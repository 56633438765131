.about{
    padding: 64px 24px;
}
.link-img-background img{
    height: 128px;
}
.link-card{
    display: flex;
    flex-direction: row;
    padding: 24px;
    border: 2px solid #000000;
    border-radius: 15px;
    background-color: #fff;
    margin: 48px 0;
}
.link-text{
    margin-left: 16px;
}
.about-nav{
    background-color: #050505;
    padding: 32px 0;
    margin-bottom: 0px;
}
.about-nav .container{
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.about a{
    color: #000000;
    text-decoration: underline;
    font-weight: bold;
    padding-bottom: 8px;
}