.hero{
    padding-top: 32px;
    position: relative;
    color: #fff;
    padding-bottom: 64px;
}
nav{
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
}
.hero-background{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 50vh;
    width: 100%;
    background-color: #050505;
}