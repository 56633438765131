.button{
    height: 60px;
    width: 160px;
    background-color: #42B883;
    border-radius: 5px;
    border: none;
    text-align: center;
    vertical-align: center;
    padding: 0 15px;
    color: #fff;
    margin-left: 2px;
}