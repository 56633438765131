* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
button:focus{
    outline: 0
}
#root{
    position: relative
}
body{
    font-family: 'Circular Std', 'sans-serif';
    background-color: #f5f5f5;
}
h1{
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 16px;
}
h2{
    font-size: 28px;
    line-height: 36px;
    color: #000000;
    
}
h3{
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}
.subheadline{
    font-size: 20px;
    line-height: 28px;
    color: #e5e5e5
}
p{
    font-size: 18px;
    line-height: 28px;
    color: #4d4d4d
}

ul li{
    list-style: none;
    display: block;
}
ul{
    margin: 0;
    padding: 0;
}
ul li .label,
ul li .data-point{
    display: inline-block;
}

ul li .label{
    color: #ADB5BD;
    font-size: 12px;
    font-weight: 500;
}
a:hover{
    text-decoration: none;
    color: inherit;
}

.right{
    text-align: right;
}
a{ 
    color: inherit;
    text-decoration: none;
    padding: inherit;
    font-weight: normal;    
}
a.link{ 
    color: #000000;
    text-decoration: underline;
    padding: inherit;
    font-weight: bold;    
}
.text-center{
    text-align: center;
}
.container-64{
    padding: 0 64px;
}
.fallacy {
    margin-bottom: 30px;
}
.bold{
    font-weight: bold;
}
button{
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    background-color: #000000;
    border-radius: 5px;
    padding: 8px 24px;
    border: none;
}