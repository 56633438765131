.modal-dialog.modal-sorry{
    max-width: 850px;
}
.modal-sorry .modal-body{
    padding: 50px 20px;
}
.modal-sorry-img{
    width: 90%;
    height: auto;
}
h2.oops{
    font-size: 44px;
    color: #343A40;
}
h3.sorry{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 35px;
}
p.sorry-text{
    width: 85%;
    font-size: 18px;
    color: #868E96;
    line-height: 26px;
}
.small-text{
    font-size: 14px;
    color: #868E96;
    font-style: italic;
    margin-top: 15px;
    display: block;
}