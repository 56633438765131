.logic-thumbnail-outer{
    background-color: #fff;
    border-radius: 15px;
    border: none;
    padding: 8px;
    height: auto;
    display: flex;
    align-items: stretch;
    height: 480px;
    box-shadow: 0 1px 3px 0 rgba(37,32,31,.3)
}
.logic-thumbnail-outer:hover{
    box-shadow: 0 1px 10px 0 rgba(37,32,31,.3)
}
.logic-thumbnail-inner{
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 16px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.thumbnail-img{
    width: 96px;
}
.thumbnail-number{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    padding-top: 8px;
}
.thumbnail-number span{
    font-size: 48px;
    line-height: 48px;
    font-weight: bold;
    color: #E5E5E5;
}

.arrow{
    margin-top: auto;
    margin-bottom: 16px;
}
