
@media screen and (max-width:800px){
    nav {
        margin-bottom: 32px;
    }
    h1{
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }
    p{
        font-size: 16px;
        line-height: 24px;
    }
    .hero{
        padding-bottom: 32px;
    }
    .container-64{
        padding: 0 16px;
    }
    .modal-body{
        padding: 0
    }
    .top-container{
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 16px 8px;
    }
    .center-div{
        padding-left: 16px;
        padding-right: 16px;
    }
    .top-container-text{
        margin-left: 0
    }
    .logic-thumbnail-inner{
        height: 416px;
    }
    .logic-thumbnail-outer,
    .logic-thumbnail-outer a{
        height: 448px;
    }
    .hero-background{
        height: 70vh;
    }
        
}