.modal-dialog{
    max-width: 800px;
    padding: 0;
}
.modal-body{
    padding: 0px 35px 50px 35px;
}
.modal-body p{
    color: #000000;
}
.modal-content{
    border: 0;
    overflow: hidden;
}
.top-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 24px;
    margin-bottom: 32px;
    
}

.top-container img{
    width: 128px;
}

.center-div{
    padding-left: 48px;
    padding-right: 48px;

}
.modal-header{
    border: none;
}
blockquote{
    padding: 24px 8px 8px 24px;
    border-left: 2px solid #000000;
    margin: 16px 0;
    position: relative;
    display: block;
    background-color: rgba(229, 229, 229, 0.4);
}

.top-container-text{
    margin-left: 24px;
}
.top-container-text p{
    color: #333333;
}